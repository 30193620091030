<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="cities3262"
		tooltipMsg="manaulforcities"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'CityId', ascending: 0 }"
		modelId="CityId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #CityNameSk="list">
			<a :href="getUrl(list.row, 'sk')" target="_blank">{{ list.row.CityNameSk }}</a>
		</template>

		<template #CityNameCz="list">
			<a :href="getUrl(list.row, 'cz')" target="_blank">{{ list.row.CityNameCz }}</a>
		</template>

		<template #CityNamePl="list">
			<a :href="getUrl(list.row, 'pl')" target="_blank">{{ list.row.CityNamePl }}</a>
		</template>

		<template #CityNameDe="list">
			<a :href="getUrl(list.row, 'de')" target="_blank">{{ list.row.CityNameDe }}</a>
		</template>

		<template #CityNameEn="list">
			<a :href="getUrl(list.row, 'en')" target="_blank">{{ list.row.CityNameEn }}</a>
		</template>

		<template #CityNameHu="list">
			<a :href="getUrl(list.row, 'hu')" target="_blank">{{ list.row.CityNameHu }}</a>
		</template>

		<!-- <template #CityParentId="list">{{ list.row.CityParentName }}</template> -->

		<template #TerritoryId="list">{{ list.row.CityTerritoryName }}</template>

		<template #DistrictId="list">{{ list.row.CityDistrictName }}</template>

		<template #CountryId="list">
			<country-flag :countryId="list.row.CountryId"></country-flag>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { listToGroupedDropdownOptions, setDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import serviceDictionary from '@/services/service/dictionary.service';
import siteUrlBuilder from '@/services/helpers/site-url-builder';

import { tableColumns } from './city.table-data';

export default {
	name: 'CityTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'CityListItem',
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(CityId = 0) {
			this.$router.push({
				name: links.cityDetailName,
				params: { CityId },
			});
		},

		async prepareColumns(columns) {
			const countryGroupLabels = this.$store.getters.countryDictLabels;
			const [territoryEnum, districtEnum] = await Promise.all([
				serviceDictionary.getTerritoryEnum(),
				serviceDictionary.getDistrictEnum(),
			]);

			let cols = prepareTerritoryId(columns);
			cols = prepareDistrictId(cols);

			return cols;

			function prepareTerritoryId(columns) {
				const values = listToGroupedDropdownOptions(territoryEnum.territorySelectorItems, countryGroupLabels, {
					groupBy: 'CountryId',
				});

				return setDropdownOptions(columns, 'TerritoryId', values);
			}

			function prepareDistrictId(columns) {
				const values = listToGroupedDropdownOptions(districtEnum.districtSelectorItems, countryGroupLabels, {
					groupBy: 'CountryId',
				});

				return setDropdownOptions(columns, 'DistrictId', values);
			}
		},

		getUrl(row, lang) {
			return siteUrlBuilder.buildUrl(row.DomainUrl, lang) + '/' + row.CityUrlName;
		},
	},
};
</script>