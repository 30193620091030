import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'CityId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CityNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameLokalSk',
		i18n: 'localsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameLokalCz',
		i18n: 'localcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameLokalPl',
		i18n: 'localpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameLokalEn',
		i18n: 'locallen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameLokalDe',
		i18n: 'localde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityNameLokalHu',
		i18n: 'localhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityUrlName',
		i18n: 'url',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CityOrder',
		i18n: 'positiononhomep3263',
		hidden: true,
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CityGoogleMapsZoom',
		i18n: 'zoomonmap3264',
		hidden: true,
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CityZip',
		i18n: 'zip2577',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	// {
	// 	model: 'CityParentId',
	// 	i18n: 'parentofcity',
	// 	sortable: true,
	// 	filter: 'autocomplete',
	// 	filterOptions: {
	// 		props: {
	// 			customParams: { withoutParent: 'true' },
	// 			url: process.env.VUE_APP_ROOT_API + 'City/GetCities',
	// 		},
	// 	},
	// },
	{
		model: 'CityParentName',
		i18n: 'parentofcity',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DistrictId',
		i18n: 'district',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'TerritoryId',
		i18n: 'county',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
];
